import React, {Fragment} from 'react';
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import processes from '../processes';

// Create styles
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#444',
    borderBottomWidth: 0,
  },
  header: {
    color: 'white',
    backgroundColor: '#444',
  },
  lastRow: {
    borderBottomWidth: 1,
  },
  cell: {
    flexGrow: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    borderRightWidth: 1,
    borderRightColor: '#444',
    justifyContent: 'center',
  },
  lastCell: {
    borderRightWidth: 0,
  },
  label: {
    flexGrow: 0,
    flexBasis: 60,
    fontWeight: '500',
  },
  wXLarge: {
    flexGrow: 0,
    flexBasis: 70,
  },
  wLarge: {
    flexGrow: 0,
    flexBasis: 60,
  },
  wMedium: {
    flexGrow: 0,
    flexBasis: 55,
  },
  wSmall: {
    flexGrow: 0,
    flexBasis: 45,
  },
  wXSmall: {
    flexGrow: 0,
    flexBasis: 38,
  },
  text: {
    paddingBottom: 20, // to fix control lineheight
  },
});

// Create Document Component
export default function MyDocument({
  dispatchRecords,
  items,
  suppliers: _suppliers,
}) {
  const suppliers = _suppliers.reduce((sum, x) => {
    sum[x.id] = {
      phone: x.phone,
    };
    return sum;
  }, {});

  return (
    <Fragment>
      {Object.keys(processes).map((key, idx) => {
        const records = dispatchRecords.filter((x) => x.process === key);
        if (records.length === 0) {
          return null;
        }

        if (key === '裁切') {
          return (
            <ProcessCropDocument
              key={idx}
              dispatchRecords={records}
              items={items}
              suppliers={suppliers}
            />
          );
        }

        if (key === '印刷') {
          return (
            <ProcessPrintDocument
              key={idx}
              dispatchRecords={records}
              items={items}
              suppliers={suppliers}
            />
          );
        }

        return (
          <Fragment key={idx}>
            <View style={[styles.row, styles.header]}>
              <View style={[styles.cell, styles.wXLarge]}>
                <Text style={styles.text}>{key}</Text>
              </View>
              <View style={[styles.cell, styles.wMedium]}>
                <Text style={styles.text}>項目</Text>
              </View>
              <View style={styles.cell}>
                <Text style={styles.text}>內容</Text>
              </View>
              <View style={[styles.cell, styles.wMedium]}>
                <Text style={styles.text}>規格</Text>
              </View>
              <View style={[styles.cell, styles.wSmall]}>
                <Text style={styles.text}>數量</Text>
              </View>
              <View style={[styles.cell, styles.wSmall]}>
                <Text style={styles.text}>備註</Text>
              </View>
              <View style={[styles.cell, styles.wXSmall, styles.lastCell]}>
                <Text style={styles.text}>完工</Text>
              </View>
            </View>
            {records.map((r, idx) => (
              <View
                style={[
                  styles.row,
                  Object.keys(records).length - 1 === idx ? styles.lastRow : {},
                ]}
                key={idx}>
                <View style={[styles.cell, styles.wXLarge]}>
                  <Text style={styles.text}>
                    {`${r.supplier_name}
${suppliers[r.supplier]?.tel}`}
                  </Text>
                  {/* <Text style={styles.text} debug={true}></Text> */}
                </View>
                <View style={[styles.cell, styles.wMedium]}>
                  <Text style={styles.text}>{`${items[r.item_index]?.name}
${r.name_note}`}</Text>
                </View>
                <View style={styles.cell}>
                  <Text style={styles.text}>{`${r.description}
${r.description_note}`}</Text>
                </View>
                <View style={[styles.cell, styles.wMedium]}>
                  <Text style={styles.text}>{r.unit}</Text>
                </View>
                <View style={[styles.cell, styles.wSmall]}>
                  <Text style={styles.text}>{r.quantity}</Text>
                </View>
                <View style={[styles.cell, styles.wSmall]}>
                  <Text style={styles.text}>{r.note}</Text>
                </View>
                <View style={[styles.cell, styles.wXSmall, styles.lastCell]}>
                  <Text style={styles.text}>{r.complete_date}</Text>
                </View>
              </View>
            ))}
          </Fragment>
        );
      })}
    </Fragment>
  );
}

function ProcessCropDocument({dispatchRecords: records, items, suppliers}) {
  return (
    <Fragment>
      <View style={[styles.row, styles.header]}>
        <View style={[styles.cell, styles.wXLarge]}>
          <Text style={styles.text}>裁切</Text>
        </View>
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>項目</Text>
        </View>
        <View style={styles.cell}>
          <Text style={styles.text}>內容</Text>
        </View>
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>規格</Text>
        </View>
        <View style={[styles.cell, styles.wSmall]}>
          <Text style={styles.text}>數量</Text>
        </View>
        <View style={[styles.cell, styles.wLarge]}>
          <Text style={styles.text}>裁切尺寸</Text>
        </View>
        <View style={[styles.cell, styles.wLarge]}>
          <Text style={styles.text}>完成尺寸</Text>
        </View>
        <View style={[styles.cell, styles.wSmall]}>
          <Text style={styles.text}>模數</Text>
        </View>
        <View style={[styles.cell, styles.wSmall]}>
          <Text style={styles.text}>備註</Text>
        </View>
        <View style={[styles.cell, styles.wXSmall, styles.lastCell]}>
          <Text style={styles.text}>完工</Text>
        </View>
      </View>
      {records.map((r, idx) => (
        <View
          style={[
            styles.row,
            Object.keys(records).length - 1 === idx ? styles.lastRow : {},
          ]}
          key={idx}>
          <View style={[styles.cell, styles.wXLarge]}>
            <Text style={styles.text}>
              {`${r.supplier_name}
${suppliers[r.supplier]?.phone}`}
            </Text>
            {/* <Text style={styles.text} debug={true}></Text> */}
          </View>
          <View style={[styles.cell, styles.wMedium]}>
            <Text style={styles.text}>{`${items[r.item_index]?.name}
${r.name_note}`}</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.text}>{`${r.description}
${r.description_note}`}</Text>
          </View>
          <View style={[styles.cell, styles.wMedium]}>
            <Text style={styles.text}>{r.unit}</Text>
          </View>
          <View style={[styles.cell, styles.wSmall]}>
            <Text style={styles.text}>{r.quantity}</Text>
          </View>
          <View style={[styles.cell, styles.wLarge]}>
            <Text style={styles.text}>{r.cut_size}</Text>
          </View>
          <View style={[styles.cell, styles.wLarge]}>
            <Text style={styles.text}>{r.complete_size}</Text>
          </View>
          <View style={[styles.cell, styles.wSmall]}>
            <Text style={styles.text}>{r.modulus}</Text>
          </View>
          <View style={[styles.cell, styles.wSmall]}>
            <Text style={styles.text}>{r.note}</Text>
          </View>
          <View style={[styles.cell, styles.wXSmall, styles.lastCell]}>
            <Text style={styles.text}>{r.complete_date}</Text>
          </View>
        </View>
      ))}
    </Fragment>
  );
}

function ProcessPrintDocument({dispatchRecords: records, items, suppliers}) {
  return (
    <Fragment>
      <View style={[styles.row, styles.header]}>
        <View style={[styles.cell, styles.wXLarge]}>
          <Text style={styles.text}>印刷</Text>
        </View>
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>項目</Text>
        </View>
        <View style={styles.cell}>
          <Text style={styles.text}>內容</Text>
        </View>
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>規格</Text>
        </View>
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>實數(車)</Text>
        </View>
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>放損(車)</Text>
        </View>
        <View style={[styles.cell, styles.wXSmall]}>
          <Text style={styles.text}>色數</Text>
        </View>
        <View style={[styles.cell, styles.wXSmall]}>
          <Text style={styles.text}>台數</Text>
        </View>
        <View style={[styles.cell, styles.wSmall]}>
          <Text style={styles.text}>備註</Text>
        </View>
        <View style={[styles.cell, styles.wXSmall, styles.lastCell]}>
          <Text style={styles.text}>完工</Text>
        </View>
      </View>
      {records.map((r, idx) => (
        <View
          style={[
            styles.row,
            Object.keys(records).length - 1 === idx ? styles.lastRow : {},
          ]}
          key={idx}>
          <View style={[styles.cell, styles.wXLarge]}>
            <Text style={styles.text}>
              {`${r.supplier_name}
${suppliers[r.supplier]?.phone}`}
            </Text>
            {/* <Text style={styles.text} debug={true}></Text> */}
          </View>
          <View style={[styles.cell, styles.wMedium]}>
            <Text style={styles.text}>{`${items[r.item_index]?.name}
${r.name_note}`}</Text>
          </View>
          <View style={styles.cell}>
            <Text style={styles.text}>{`${r.description}
${r.description_note}`}</Text>
          </View>
          <View style={[styles.cell, styles.wMedium]}>
            <Text style={styles.text}>{r.unit}</Text>
          </View>
          <View style={[styles.cell, styles.wMedium]}>
            <Text style={styles.text}>{r.quantity}</Text>
          </View>
          <View style={[styles.cell, styles.wMedium]}>
            <Text style={styles.text}>{r.loss}</Text>
          </View>
          <View style={[styles.cell, styles.wXSmall]}>
            <Text style={styles.text}>{r.color_amount}</Text>
          </View>
          <View style={[styles.cell, styles.wXSmall]}>
            <Text style={styles.text}>{r.set_amount}</Text>
          </View>
          <View style={[styles.cell, styles.wSmall]}>
            <Text style={styles.text}>{r.note}</Text>
          </View>
          <View style={[styles.cell, styles.wXSmall, styles.lastCell]}>
            <Text style={styles.text}>{r.complete_date}</Text>
          </View>
        </View>
      ))}
    </Fragment>
  );
}
