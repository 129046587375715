import React, {Fragment} from 'react';
import {Page, Text, View, Document, StyleSheet} from '@react-pdf/renderer';
import processes from '../processes';

// Create styles
const styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    borderWidth: 1,
    borderColor: '#444',
    borderBottomWidth: 0,
  },
  header: {
    color: 'white',
    backgroundColor: '#444',
  },
  lastRow: {
    borderBottomWidth: 1,
  },
  cell: {
    flexGrow: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    borderRightWidth: 1,
    borderRightColor: '#444',
    justifyContent: 'center',
  },
  lastCell: {
    borderRightWidth: 0,
  },
  label: {
    flexGrow: 0,
    flexBasis: 60,
    fontWeight: '500',
  },
  wXLarge: {
    flexGrow: 0,
    flexBasis: 70,
  },
  wLarge: {
    flexGrow: 0,
    flexBasis: 60,
  },
  wMedium: {
    flexGrow: 0,
    flexBasis: 55,
  },
  wSmall: {
    flexGrow: 0,
    flexBasis: 45,
  },
  wXSmall: {
    flexGrow: 0,
    flexBasis: 38,
  },
  text: {
    paddingBottom: 20, // to fix control lineheight
  },
});

// Create Document Component
export default function MyDocument({record, items, projects}) {
  return (
    <Fragment>
      <View style={[styles.row, styles.header]}>
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>品項</Text>
        </View>
        <View style={[styles.cell]}>
          <Text style={styles.text}>規格</Text>
        </View>
        {/* <View style={styles.cell}>
          <Text style={styles.text}>紙質磅數</Text>
        </View>
        <View style={[styles.cell, styles.wSmall]}>
          <Text style={styles.text}>顏色</Text>
        </View> */}
        <View style={[styles.cell, styles.wMedium]}>
          <Text style={styles.text}>數量</Text>
        </View>
        <View style={[styles.cell, styles.wSmall]}>
          <Text style={styles.text}>單價</Text>
        </View>
        <View style={[styles.cell, styles.wLarge, styles.lastCell]}>
          <Text style={styles.text}>項目金額</Text>
        </View>
      </View>
      {items.map((i, idx) => {
        let project = projects[i.project];
        let itemInProject;
        if (project) {
          itemInProject = project.items[i.item_index];
        }
        if (!itemInProject) {
          return null;
        }

        return (
          <View style={styles.row} key={idx}>
            <View style={[styles.cell, styles.wMedium]}>
              <Text style={styles.text}>{itemInProject.name}</Text>
              {/* <Text style={styles.text} debug={true}></Text> */}
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>{`${
                itemInProject.complete_size
                  ? '完成尺寸' + itemInProject.complete_size
                  : ''
              }
${
  itemInProject.expand_size ? '展開尺寸' + itemInProject.expand_size : ''
}`}</Text>
            </View>
            {/* <View style={[styles.cell]}>
            <Text style={styles.text}>{}</Text>
          </View>
          <View style={[styles.cell, styles.wSmall]}>
            <Text style={styles.text}>{}</Text>
          </View> */}
            <View style={[styles.cell, styles.wMedium]}>
              <Text style={styles.text}>
                {itemInProject.quantity}
                {itemInProject.unit}
              </Text>
            </View>
            <View style={[styles.cell, styles.wSmall]}>
              <Text style={styles.text}>{i.unit_price}</Text>
            </View>
            <View style={[styles.cell, styles.wLarge, styles.lastCell]}>
              <Text style={styles.text}>{i.price}</Text>
            </View>
          </View>
        );
      })}
      <View style={styles.row}>
        <View style={styles.cell}>
          <Text style={styles.text}>銷售額</Text>
        </View>
        <View style={[styles.cell, styles.wLarge, styles.lastCell]}>
          <Text style={styles.text}>{record?.sales}</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.cell}>
          <Text style={styles.text}>稅額</Text>
        </View>
        <View style={[styles.cell, styles.wLarge, styles.lastCell]}>
          <Text style={styles.text}>{record?.tax}</Text>
        </View>
      </View>
      <View style={[styles.row, styles.lastRow]}>
        <View style={styles.cell}>
          <Text style={styles.text}>總計</Text>
        </View>
        <View style={[styles.cell, styles.wLarge, styles.lastCell]}>
          <Text style={styles.text}>{record?.total}</Text>
        </View>
      </View>
    </Fragment>
  );
}
