import React, {useCallback, useState, useEffect, useContext} from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import {Context} from '../AppContext';
import useProject from '../hooks/use-project';
import DispatchRecordTable from './DispatchRecordPdf';
import {date} from '../Utils';
import {DISPATCH_NOTES} from '../dictionary';
import useProcess from '../hooks/use-processes';
import useSupplierList from '../hooks/use-supplier-list';
import {Alert} from 'antd';

Font.register({
  family: 'SourceHanSansCN',
  src: 'https://static.revtel-api.com/common/SourceHanSansCN-Regular.ttf',
});

Font.registerHyphenationCallback((word) => {
  if (word.length === 1) {
    return [word];
  }

  return Array.from(word)
    .map((char) => [char, ''])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'SourceHanSansCN',
    backgroundColor: '#fff',
    fontSize: 10,
    padding: 10,
    lineHeight: 1,
  },
  section: {
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: '#ccc',
    borderBottomWidth: 0,
  },
  lastRow: {
    borderBottomWidth: 2,
  },
  cell: {
    flexGrow: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    borderRightWidth: 2,
    borderRightColor: '#ccc',
    justifyContent: 'center',
  },
  lastCell: {
    borderRightWidth: 0,
  },
  label: {
    flexGrow: 0,
    flexBasis: 60,
    fontWeight: '500',
  },
  w150: {
    flexGrow: 0,
    flexBasis: 150,
  },
  text: {
    paddingBottom: 15,
  },
});

// Create Document Component
function MyDocument({
  project,
  items,
  customer,
  record,
  dispatchRecords,
  suppliers,
}) {
  const _items = items.filter((i) => !i.is_deleted);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>工單單號</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.text}>{record?.display_id}</Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>客戶名稱</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{customer?.name}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>印件名稱</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.text}>{project?.name}</Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>工單日期</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{date.format(record?.created)}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>印製數量</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>
                {_items
                  .map((i) => `${i.name}${i.quantity || '0'}${i.unit || ''}`)
                  .join(', ')}
              </Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>交貨日期</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{date.format(record?.final_date)}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>完成尺寸</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>
                {_items
                  .map((i) => `${i.name}${i.complete_size || ''}`)
                  .join(', ')}
              </Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>業務</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{record?.staff_fullname}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>展開尺寸</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>
                {_items
                  .map((i) => `${i.name}${i.expand_size || ''}`)
                  .join(', ')}
              </Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>工務</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{record?.worker_name}</Text>
            </View>
          </View>

          <View style={[styles.row, styles.lastRow]}>
            <View style={[styles.cell, styles.lastCell]}>
              <Text style={styles.text}>
                {Object.keys(DISPATCH_NOTES).map(
                  (key) =>
                    `${
                      record &&
                      Array.isArray(record.note) &&
                      record.note.indexOf(key) >= 0
                        ? '✓'
                        : '□'
                    } ${key}  `,
                )}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <DispatchRecordTable
            dispatchRecords={dispatchRecords}
            items={items}
            suppliers={suppliers}
          />
        </View>
      </Page>
    </Document>
  );
}

export default function DispatchPdf({id}) {
  const app = useContext(Context);
  const [record, setRecord] = useState({});
  const {project, items, customer, loading: projectLoading} = useProject({
    id: record?.project,
  });
  const {dispatchRecords} = useProcess({dispatch: record, items});
  const [loading, _setLoading] = useState(false);
  const {records: suppliers} = useSupplierList({noPage: true});
  const getRecord = useCallback(async () => {
    if (id) {
      try {
        app.actions.setLoading(true);
        let resp = await app.actions.getDispatch({query: {id}});
        setRecord((prev) => ({...prev, ...resp}));
        app.actions.setLoading(false);
      } catch (ex) {
        console.warn(ex);
      }
    }
  }, [app.actions, id]);

  const getUsers = useCallback(async () => {
    if (record && !record.worker_name) {
      try {
        let resp = await app.actions.getUsers();
        let worker = resp.find((u) => u.id === record.worker);
        if (worker) {
          setRecord((prev) => ({...prev, worker_name: worker.data?.fullname}));
        }
      } catch (err) {}
    }
  }, [record]);

  useEffect(() => {
    (async () => {
      _setLoading(true);
      await Promise.all([getRecord(), getUsers()]);
      _setLoading(false);
    })();
  }, [getRecord]);

  if (loading || projectLoading) {
    return (
      <div>
        <Alert
          message={`載入專案資料${projectLoading ? '中' : '完成'}`}
          showIcon={true}
          type={projectLoading ? 'warning' : 'success'}
          style={{marginBottom: 10}}
        />
        <Alert
          message={`載入工單資料${loading ? '中' : '完成'}`}
          showIcon={true}
          type={loading ? 'warning' : 'success'}
          style={{marginBottom: 10}}
        />
      </div>
    );
  }

  return (
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <MyDocument
        project={project}
        items={items}
        customer={customer}
        record={record}
        dispatchRecords={dispatchRecords}
        suppliers={suppliers}
      />
    </PDFViewer>
  );
}
