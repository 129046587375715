import React, {useCallback, useState, useEffect, useContext} from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import {INFORMATION} from '../dictionary';

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'SourceHanSansCN',
    backgroundColor: '#fff',
    fontSize: 10,
    padding: 10,
    lineHeight: 1,
  },
  section: {
    marginBottom: 10,
  },
  header: {
    color: 'white',
    backgroundColor: '#444',
  },
  row: {
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: '#ccc',
    borderBottomWidth: 0,
  },
  lastRow: {
    borderBottomWidth: 2,
  },
  cell: {
    flexGrow: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    borderRightWidth: 2,
    borderRightColor: '#ccc',
    justifyContent: 'center',
  },
  lastCell: {
    borderRightWidth: 0,
  },
  label: {
    flexGrow: 0,
    flexBasis: 60,
    fontWeight: '500',
  },
  w150: {
    flexGrow: 0,
    flexBasis: 150,
  },
  text: {
    paddingBottom: 15,
  },
});

// Create Document Component
export default function MyDocument() {
  return (
    <View style={styles.section}>
      <View style={[styles.row, styles.header]}>
        <View style={[styles.cell, styles.lastCell]}>
          <Text style={styles.text}>付款資訊</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={styles.cell}>
          <Text style={styles.text}>銀行帳號：{INFORMATION.account}</Text>
          <Text style={styles.text}>銀行：{INFORMATION.bank}</Text>
          <Text style={styles.text}>帳戶名稱：{INFORMATION.account_name}</Text>
        </View>
        <View style={[styles.cell, styles.lastCell]}>
          <Text style={styles.text}>公司地址：{INFORMATION.address}</Text>
          <Text style={styles.text}>
            工廠地址：{INFORMATION.factory_address}
          </Text>
          <Text style={styles.text}>電話：{INFORMATION.tel}</Text>
          <Text style={styles.text}>傳真：{INFORMATION.fax}</Text>
          <Text style={styles.text}>信箱：{INFORMATION.email}</Text>
        </View>
      </View>
      <View style={[styles.row, styles.header]}>
        <View style={[styles.cell, styles.lastCell]}>
          <Text style={styles.text}>備註</Text>
        </View>
      </View>
      <View style={styles.row}>
        <View style={[styles.cell, styles.lastCell]}>
          <Text style={styles.text}>本估價單有效期間7天。</Text>
          <Text style={styles.text}>
            凡第一次交易之客戶，公司規定必須以現金或即期票付款。
          </Text>
          <Text style={styles.text}>
            本報帳單如貴公司簽署製作，視同訂購契約，中途如有更改或因故停止製作，本公司得依已製作部分收取製作費用。
          </Text>
          <Text style={styles.text}>
            校稿與發案皆採用Mail、Line、傳真等系統文字為執行憑據。
          </Text>
          <Text style={styles.text}>
            本報價單如蒙同意，則轉為買賣契約，金額無誤後煩請蓋章（簽）回傳。
          </Text>
          <Text style={styles.text}>
            客戶提供之文字圖片等相關製作資料，保證絕無他人著作權，版權或涉及任何法律問題，如有涉及概由委製公司負責。
          </Text>
          <Text style={styles.text}>
            客戶提供之檔案或稿件，經確認打樣複印後所產生之瑕疵問題，本公司概不負責，（客戶請仔細校隊內容文字圖片）。
          </Text>
          <Text style={styles.text}>
            客戶提供完告電子檔，報價含數位打樣1次。
          </Text>
          <Text style={styles.text}>
            以上含北縣市一處運費（其他縣市森費另計/實報實銷），每筆交易未滿1000元，加收100元運費。
          </Text>
        </View>
      </View>

      <View style={[styles.row, styles.lastRow]}>
        <View style={styles.cell}>
          <Text style={styles.text}>甲方簽認：</Text>
          <Text style={styles.text}>{INFORMATION.title}</Text>
          <Text style={styles.text}>甲方代表：{INFORMATION.name}</Text>
          <Text style={styles.text}>統一編號：{INFORMATION.uni_number}</Text>
          <Text style={styles.text}>手機：{INFORMATION.phone}</Text>
        </View>
        <View style={[styles.cell, styles.lastCell]}>
          <Text style={styles.text}>乙方簽認：</Text>
          <Text style={styles.text}>乙方代表：</Text>
          <Text style={styles.text}>統一編號：</Text>
          <Text style={styles.text}>公司地址</Text>
        </View>
      </View>
    </View>
  );
}
