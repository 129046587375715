import React, {useCallback, useState, useEffect, useContext} from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
  Font,
} from '@react-pdf/renderer';
import {Context} from '../AppContext';
import BottomPdf from './QuotationBottomPdf';
import QuotationItems from './QuotationItemsPdf';
import {date} from '../Utils';
import {DISPATCH_NOTES} from '../dictionary';
import useQuotation from '../hooks/use-quotation';
import useQuotationItems from '../hooks/use-quotation-items';
import {Alert} from 'antd';

Font.register({
  family: 'SourceHanSansCN',
  src: 'https://static.revtel-api.com/common/SourceHanSansCN-Regular.ttf',
});

Font.registerHyphenationCallback((word) => {
  if (word.length === 1) {
    return [word];
  }

  return Array.from(word)
    .map((char) => [char, ''])
    .reduce((arr, current) => {
      arr.push(...current);
      return arr;
    }, []);
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: 'SourceHanSansCN',
    backgroundColor: '#fff',
    fontSize: 10,
    padding: 10,
    lineHeight: 1,
  },
  section: {
    marginBottom: 10,
  },
  row: {
    flexDirection: 'row',
    borderWidth: 2,
    borderColor: '#ccc',
    borderBottomWidth: 0,
  },
  lastRow: {
    borderBottomWidth: 2,
  },
  cell: {
    flexGrow: 1,
    paddingHorizontal: 5,
    paddingVertical: 0,
    borderRightWidth: 2,
    borderRightColor: '#ccc',
    justifyContent: 'center',
  },
  lastCell: {
    borderRightWidth: 0,
  },
  label: {
    flexGrow: 0,
    flexBasis: 60,
    fontWeight: '500',
  },
  w150: {
    flexGrow: 0,
    flexBasis: 150,
  },
  text: {
    paddingBottom: 15,
  },
});

// Create Document Component
function MyDocument({customer, record, items, projects, dispatches}) {
  const _items = items.filter((i) => !i.is_deleted);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>客戶抬頭</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.text}>{customer?.company_title}</Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>報價單號</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{record?.display_id}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>客戶統編</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.text}>{customer?.uni_number}</Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>公司電話</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{customer?.tel}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>聯絡人</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>{customer?.contact_name1}</Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>聯絡手機</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{customer?.contact_phone1}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>發票地址</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>{customer?.invoice_address}</Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>送貨地址</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{customer?.address}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>信箱</Text>
            </View>
            <View style={[styles.cell, styles.lastCell]}>
              <Text style={styles.text}>{customer?.email}</Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>印件名稱</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>
                {Object.values(projects)
                  .map((p) => p.name)
                  .join(', ')}
              </Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>報價日期</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>{date.format(record?.quote_date)}</Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>印製數量</Text>
            </View>
            <View style={[styles.cell]}>
              <Text style={styles.text}>
                {Object.values(projects)
                  .map((p) => p.items.filter((i) => !i.is_deleted))
                  .reduce((sum, arr) => sum.concat(arr), [])
                  .map((i) => `${i.name}${i.quantity}${i.unit}`)
                  .join(', ')}
              </Text>
            </View>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>交貨日期</Text>
            </View>
            <View style={[styles.cell, styles.lastCell, styles.w150]}>
              <Text style={styles.text}>
                {date.format(record?.delivery_date)}
              </Text>
            </View>
          </View>
          <View style={[styles.row, styles.lastRow]}>
            <View style={[styles.cell, styles.label]}>
              <Text style={styles.text}>備註</Text>
            </View>
            <View style={[styles.cell, styles.lastCell]}>
              <Text style={styles.text}>{record?.note}</Text>
            </View>
          </View>
        </View>

        <View style={styles.section}>
          <QuotationItems record={record} items={items} projects={projects} />
        </View>

        <BottomPdf />
      </Page>
    </Document>
  );
}

export default function DispatchPdf({id}) {
  const app = useContext(Context);
  const {loading, record, getRecord, items, customer} = useQuotation({id});
  const {loading: itemLoading, projects, dispatches} = useQuotationItems({
    quotation: record,
  });

  useEffect(() => {
    app.actions.setLoading(false);
  }, []);

  if (loading || itemLoading) {
    return (
      <div>
        <Alert
          message={`載入專案及工單資料${itemLoading ? '中' : '完成'}`}
          showIcon={true}
          type={itemLoading ? 'warning' : 'success'}
          style={{marginBottom: 10}}
        />
        <Alert
          message={`載入報價單資料${loading ? '中' : '完成'}`}
          showIcon={true}
          type={loading ? 'warning' : 'success'}
          style={{marginBottom: 10}}
        />
      </div>
    );
  }

  return (
    <PDFViewer style={{width: '100%', height: '100vh'}}>
      <MyDocument
        customer={customer}
        record={record}
        items={items}
        projects={projects}
        dispatches={dispatches}
      />
    </PDFViewer>
  );
}
