import React, {useState} from 'react';
import Dispatch from '../Pdfs/DispatchPdf';
import Quotation from '../Pdfs/QuotationPdf';
import styled from 'styled-components';
import {previousPage} from '../Utils';
const queryString = require('query-string');

const TYPES = {
  dispatch: '工單',
  quotation: '報價單',
};

export default function PdfPreview(props) {
  const {type, id} = queryString.parse(props.location.search);

  if (!TYPES[type] || !id) {
    return <Wrapper>找不到PDF格式或編號</Wrapper>;
  }
  return (
    <Wrapper>
      <div className="action-bar">
        <div
          onClick={() => previousPage()}
          style={{color: '#aaa', cursor: 'pointer'}}>
          {'↼ '}回到前頁{' '}
        </div>

        <div style={{flex: 1}} />
      </div>

      {type === 'dispatch' ? (
        <Dispatch id={id} />
      ) : type === 'quotation' ? (
        <Quotation id={id} />
      ) : null}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 72px 40px;
  background-color: white;

  & > .action-bar {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    & > .loading {
      margin-left: 10px;
    }
  }
`;
